@font-face {
  font-family: "Brandon-Grotesque";
  src: url(data-url:./fonts/Brandon_reg.otf) format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Brandon-Grotesque";
  src: url(data-url:./fonts/Brandon_bld.otf) format("opentype");
  font-weight: 700;
  font-style: normal;
}

@property --color-primary {
  syntax: "<color>";
  inherits: false;
  initial-value: #731f4a;
}
@property --color-secondary {
  syntax: "<color>";
  inherits: false;
  initial-value: #ffffff;
}
@property --color-tertiary {
  syntax: "<color>";
  inherits: false;
  initial-value: #f0627f;
}
@property --color-quaternary {
  syntax: "<color>";
  inherits: false;
  initial-value: #e3b364;
}
@property --color-grey {
  syntax: "<color>";
  inherits: false;
  initial-value: #515151;
}
@property --color-transparent {
  syntax: "<color>";
  inherits: false;
  initial-value: transparent;
}
@property --color-dark-background {
  syntax: "<color>";
  inherits: false;
  initial-value: rgba(0, 0, 0, 0.5);
}

.fol-widget {
  font-family: Brandon-Grotesque;
  font-size: 16px;
  position: fixed;
  bottom: 45px;
  right: 45px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: end;
  gap: 25px;
  z-index: 100;
  max-height: 90vh;
}
.button-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-transparent);
  width: 225px;
  height: 60px;
}
.close-widget-icon {
  cursor: pointer;
  position: absolute;
  top: 30;
  right: 30;
  transform: scale(1.5);
}
.icon {
  cursor: pointer;
  width: 70%;
  position: absolute;
  top: -8px;
  left: -20px;
}
.hide-widget-button-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 4px;
  right: 0;
  bottom: 60px;
  cursor: pointer;
  background-color: var(--color-secondary);
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
}
.button-text-container {
  color: var(--color-secondary);
  background-color: var(--color-primary);
  padding: 6px 20px 6px 60px;
  border-radius: 0px 50px 50px 0px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
}
.font-regular {
  font-weight: 400;
}
.font-bold {
  font-weight: 700;
}
.iframe-container {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  width: 400px;
  height: 100vh;
  overflow: hidden;
  opacity: 1;
  transition: all 0.3s ease-in;
  position: relative;
}
.background-container {
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--color-dark-background);
  width: 100vw;
  height: 100vh;
  z-index: 99;
  transition: opacity 0.2s ease;
}

.hidden {
  display: none;
  translate: 0;
  opacity: 0;
}

.loading {
  position: absolute;
  opacity: 0;
  height: 0;
  overflow: hidden;
}

@media (max-width: 620px) {
  .fol-widget {
    bottom: 20;
    right: 0;
    z-index: 19;
    max-height: 85vh;
  }
  .background-container {
    z-index: 18;
  }
  .button-container {
    margin-right: 10px;
  }
  .hide-widget-button-container {
    right: 10;
  }
  .iframe-container {
    width: 100vw;
  }
}
