if (module.hot) {
  module.hot.accept();
}

import logoOpenSvg from "bundle-text:./icons/logoOpen.svg";
import logoCloseSvg from "bundle-text:./icons/logoClose.svg";
import hideWidgetIconSvg from "bundle-text:./icons/hideWidgetIcon.svg";
import * as mainStyles from "./styles/main.module.css";

const url = new URL(window.location.href);

const MOBILE_BREAKPOINT = 620;

const windowPathname = url.pathname;

// Check the pathname to see if it includes any of the excluded paths:
const excludedPaths = [
  "find-specialist",
  "sign-up",
  "choosing-fertility-treatment",
  "about-details-ivf",
];

const excludedPath = excludedPaths.find((path) => {
  return windowPathname.includes(path);
});

if (Boolean(excludedPath)) {
  return;
}

// create stylesheet link tag for main.css:
const handleLoadingCSS = async () => {
  try {
    const scriptSrc = document.getElementById("sign-up-widget")?.src || null;

    if (!scriptSrc) {
      console.error("missing script id");
      return;
    }

    const src = new URL(scriptSrc);
    const baseURI = src.origin;
    const linkTagHref = `${baseURI}/main.css`;

    const linkTag = document.createElement("link");
    linkTag.rel = "stylesheet";
    linkTag.type = "text/css";
    linkTag.href = linkTagHref;
    document.head.appendChild(linkTag);

    const isCSSLoaded = await new Promise((resolve, reject) => {
      linkTag.onload = () => {
        resolve(true);
      };

      linkTag.onerror = (error) => {
        reject(error);
      };
    });

    return isCSSLoaded;
  } catch (error) {
    console.error("failed to fetch main.css", error);
    return false;
  }
};

const handleCreateElement = ({
  parentContainer,
  tag,
  innerHTML,
  classList,
  eventListener,
  ...rest
}) => {
  const element = document.createElement(tag);
  classList
    ?.split(" ")
    .forEach((className) => element.classList.add(mainStyles[className]));
  parentContainer?.appendChild(element) || document.body.appendChild(element);
  element.innerHTML = innerHTML || "";
  Object.keys(rest).forEach((key) => {
    element[key] = rest[key];
  });

  return element;
};

const handleRemoveElement = (element) => {
  element.remove();
};

const createIFrameContainerContent = ({ parentContainer }) => {
  const url = new URL(window.location.href);
  const origin = url.origin;
  const environment = url.searchParams.get("environment");
  let iFrameBaseUrl = "";

  switch (environment) {
    case "development":
      iFrameBaseUrl = "http://localhost:3400";
      break;
    case "staging":
      iFrameBaseUrl = "https://fertility-outreach-staging.herokuapp.com";
      break;
    case "production":
      iFrameBaseUrl = "https://fertilityapp.fertilityoutloud.com";
      break;
    default:
      iFrameBaseUrl = "https://fertilityapp.fertilityoutloud.com";
      break;
  }

  let iFrameParams = `?signup_source=fol&signup_url=${origin}`;

  const windowParams = url.search || "";

  if (windowParams) {
    // remove the "?" from the window params
    url.searchParams.forEach((value, key) => {
      if (key === "environment") {
        return;
      }
      return (iFrameParams += `&${key}=${value}`);
    });
  }

  const iFrame = handleCreateElement({
    tag: "iframe",
    src: `${iFrameBaseUrl}/sign-up${iFrameParams}`,
    width: "100%",
    height: "100%",
    loading: "eager",
    frameBorder: 0,
    parentContainer,
  });
  // TODO: Add event listener to close the widget from inside the iframe
  return iFrame;
};

const handleGetSessionStorage = (key) => {
  return sessionStorage.getItem(key);
};

const handleSetSessionStorage = (key, value) => {
  sessionStorage.setItem(key, value);
};

// APP STARTS HERE: FOL WIDGET
const initializeFOLWidget = async () => {
  const widgetStatus = handleGetSessionStorage("widgetStatus");

  if (widgetStatus === "closed") {
    return;
  }

  // PARENT CONTAINER:
  const widgetContainer = handleCreateElement({
    tag: "div",
    classList: "fol-widget hidden",
    id: "fol-widget",
    parentContainer: document.body,
  });

  const buttonContainer = handleCreateElement({
    parentContainer: widgetContainer,
    tag: "div",
    classList: "button-container",
  });

  // Text Container:
  handleCreateElement({
    parentContainer: buttonContainer,
    tag: "div",
    classList: "button-text-container",
    innerHTML: `<span class=${mainStyles["font-regular"]}>TEXT WITH A</span> <span class=${mainStyles["font-bold"]}>FERTILITY COACH</span>`,
  });

  const hideWidgetButton = handleCreateElement({
    parentContainer: widgetContainer,
    tag: "div",
    classList: "hide-widget-button-container",
    innerHTML: hideWidgetIconSvg,
  });

  const iconContainer = handleCreateElement({
    parentContainer: buttonContainer,
    tag: "div",
    classList: "icon",
    innerHTML: logoOpenSvg,
  });

  const iFrameContainer = handleCreateElement({
    parentContainer: widgetContainer,
    tag: "div",
    classList: "iframe-container loading",
  });

  const iFrameContainerContent = createIFrameContainerContent({
    parentContainer: iFrameContainer,
  });

  const closeWidgetIconSvg = hideWidgetIconSvg;

  const closeIcon = handleCreateElement({
    parentContainer: iFrameContainer,
    tag: "div",
    classList: "close-widget-icon",
    innerHTML: closeWidgetIconSvg,
  });

  let backgroundContainer = HTMLElement;

  const handleWidgetOpen = () => {
    iconContainer.innerHTML = logoCloseSvg;
    backgroundContainer = handleCreateElement({
      tag: "div",
      classList: "background-container",
      parentContainer: document.body,
      innerHTML: "",
    });

    iFrameContainer.classList.remove(mainStyles["hidden"]);
    iFrameContainer.classList.remove(mainStyles["loading"]);

    buttonContainer.removeEventListener("click", handleWidgetOpen);
    buttonContainer.addEventListener("click", handleWidgetClose);
    closeIcon.addEventListener("click", handleWidgetClose);
    backgroundContainer.addEventListener("click", handleWidgetClose);
    widgetContainer.style.zIndex = 1000;
    backgroundContainer.style.zIndex = 999;
    if (window.innerWidth <= MOBILE_BREAKPOINT) {
      document.body.style.overflow = "hidden";
    }
  };

  const handleWidgetClose = () => {
    widgetContainer.removeAttribute("style");
    backgroundContainer.removeAttribute("style");
    document.body.removeAttribute("style");
    iconContainer.innerHTML = logoOpenSvg;
    handleRemoveElement(backgroundContainer);
    iFrameContainer.classList.add(mainStyles["hidden"]);

    closeIcon.removeEventListener("click", handleWidgetClose);
    buttonContainer.removeEventListener("click", handleWidgetClose);
    buttonContainer.addEventListener("click", handleWidgetOpen);
  };

  const hideWidget = (e) => {
    handleSetSessionStorage("widgetStatus", "closed");
    widgetContainer.remove();
  };

  hideWidgetButton.addEventListener("click", hideWidget);

  buttonContainer.addEventListener("click", handleWidgetOpen);
};

const renderFOLWidget = async () => {
  try {
    // LOAD CSS:
    const isCSSLoaded = await handleLoadingCSS();
    if (!isCSSLoaded) {
      throw new Error("CSS failed to load");
    }
    // INITIALIZE FOL WIDGET:
    await initializeFOLWidget();

    // THEN SHOW THE WIDGET:
    const widgetContainer = document.getElementById("fol-widget");
    widgetContainer.classList.remove(mainStyles["hidden"]);
    widgetContainer.removeAttribute("id");
  } catch (error) {
    console.error(error);
  }
};

renderFOLWidget();
